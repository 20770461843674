
[background-slope] {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        background-color: mat-color($primary, 50);
        width: 100vw;
        margin: calc(var(--size-medium) * -1) -50vw 0;
        top: 0;
        left: 50%;
        bottom: 0;
        overflow: hidden;
        z-index: -1;
    }

    &:after {
        position: absolute;
        content: "";
        background-color: mat-color($primary, 50);
        width: 100vw;
        height: 158px;
        margin: 0 -50vw;
        bottom: -158px;
        left: 50%;
        overflow: hidden;
        z-index: -1;
        clip-path: polygon(0 0, 100% 0, 100% 25%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 25%, 0% 100%);
    }

    ~ [box] {
        background: var(--color-light);
        border-radius: 4px;
        padding: var(--size-default);
        z-index: 1;
    }

    @media #{$small-and-down} {
        margin-bottom: calc(var(--size-default) * 3);

        &:before {
            margin: calc(var(--size-medium) * -1) -50vw 0;
        }

        &:after {
            clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
            height: calc(var(--size-default) * 3);
            bottom: calc(var(--size-default) * 3 * -1);
        }

        ~ [box] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
