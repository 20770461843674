h1, h2, h3, h4, h5, h6 {
    line-height: 1.5;
    text-overflow: ellipsis;
    padding-top: .2em;
    padding-bottom: .2em;
    overflow: hidden;

    @for $i from 1 through 6 {
        &.h#{$i} {
            $size: var(--font-size);
            $spacing: 0;
            $weight: 400;

            @if $i == 1 {
                $size: 96px;
                $spacing: -1.5px;
                $weight: 300;
            } @else if $i == 2 {
                $size: 60px;
                $spacing: -0.5px;
                $weight: 300;
            } @else if $i == 3 {
                $size: 48px;
                $spacing: 0px;
                $weight: 300;
            } @else if $i == 4 {
                $size: 34px;
                $spacing: .25px;
                $weight: 400;
            } @else if $i == 5 {
                $size: 24px;
                $spacing: 0;
                $weight: 400;
            } @else if $i == 6 {
                $size: 20px;
                $spacing: .25px;
                $weight: 500;
            }

            font-size: $size !important;
            letter-spacing: $spacing !important;
            font-weight: $weight !important;
        }
    }
}
