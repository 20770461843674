/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'utils';

@import 'mat-core-theme';
@import 'mat-custom';

@import 'fonts';

@import 'gp-global';
@import 'gp-layout';

@import 'custom-materialize';
@import 'custom-toastr';

// TODO: check them later
@import './style/typography';
@import './style/backgroud-slope';
